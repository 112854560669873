// jQuery
import $ from 'jquery';

// Bootstrap
import '../../../node_modules/bootstrap/dist/js/bootstrap.bundle';

import Typed from 'typed.js';

var options = {
  strings: ["Mental", "Emotional", "Physical"],
  typeSpeed: 120,
  startDelay: 500,
  backSpeed: 60,
  backDelay: 2000,
  // fadeOutDelay: 2000,
  fadeOut: true,
  loop: true,
  showCursor: false
}

var typed = new Typed("#typing", options);

window.$ = $;

import * as feather from 'feather-icons';
import throttle from './throttle';

feather.replace();

// const head = document.querySelector('.head');
// const headbg = document.querySelector('.head-bg');

// function handleScroll() {
//   const y = window.scrollY;
//   const height = window.innerHeight;

//   const fadeCap = height / 1.5;

//   if (y < fadeCap) {
//     head.style.opacity = `${1 - (y / fadeCap)}`;
//   }

//   if (y < height) {
//     headbg.style.opacity = `${Math.min(1 - (y / height), .75)}`;
//   }
// }

// window.addEventListener('scroll', throttle(handleScroll, 100));
